import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { ConfigProvider, Logger, NotificationService } from 'voltospa';
import { AuthService } from '../service/auth/auth-service';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from '../service/ui/ui-layout-service';
import { UiNavigationService } from '../service/ui/ui-navigation-service';
import { PageBase } from './contents/Base/page-base';
import { ValidationController, ValidationRules, ValidationControllerFactory } from 'aurelia-validation';
import { ProfileViewModel } from '../view-models/profile-view-model'
import { FeedbackViewModel } from '../view-models/feedback-view-model'
import { BootstrapErrorRenderer } from './contents/CustomElements/BootstrapErrorRenderer';
import { LoyaltyProgramViewModel, ProgramInfo, TenantViewModel } from '../view-models/tenant-view-models';
import { ProfileService } from '../service/profile/profile-service';
import { FeedbackService } from '../service/contact/feedback-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Constants } from './../constants/constants';
import { FeedbackResultViewModel } from '../view-models/feedback-result-view-Model';



@autoinject()
export class Kontakt extends PageBase {

    tenantInfo: ProgramInfo;
    public tenant: string = '';
    public loyaltyProgram: LoyaltyProgramViewModel;
    public activeTenant: TenantViewModel;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public kontakt: FeedbackViewModel = new FeedbackViewModel();
    private validationController: ValidationController;
    private userProfile: ProfileViewModel;
    public subject: string[];
    public feedbackSent: boolean;
    private serverErrorMessage: string = "Leider ist ein Fehler beim Senden der Daten aufgetreten. Bitte versuchen Sie es erneut.";

    //TODO: Wieder entfernen
    @bindable nlevel: string = "info";

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, public layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private auth: AuthService, private validationControllerFactory: ValidationControllerFactory,
        private profileService: ProfileService, private eventAggregator: EventAggregator, private feedbackService: FeedbackService, private notificationService: NotificationService) {

        super(tenantService, config, tenantProvider, layout, logger, nav);
        this.activeTenant = tenantProvider.getTenant();
        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapErrorRenderer());
        this.setValidationRules();
        this.setActiveTenant();
        this.loyaltyProgram = this.getLoyalityProgram();
        this.tenant = this.tenantProvider.getTenant().key;

        this.subject = [
            "Nachweise / Schecks digital hochladen",
            "Onlinezugang - neuen Zugang beantragen",
            "Onlinezugang - Initialpasswort",
            "Onlinezugang - Login",
            "Prämienbestellung",
            "Punktestand und -zusammenlegung",
            "Scheckheft Onlineversion (Ausdrucken / Einsehen)",
            "Scheckheft Onlineversion (Mitsammler)",
            "Scheckheft per Post (Anforderung / Nachbestellung)",
            "Teilnahme (Anmeldung und Verlauf)",
            "Sonstiges"];
        this.feedbackSent = false;
    }

    setActiveTenant() {
        this.activeTenant = this.tenantProvider.getTenant();
        this.tenantInfo = this.activeTenant.ui.programs[0];

    }

    async activate(params: any, route, navigationInstruction) {
        if (this.auth.IsAuthenticated()) {
            this.userProfile = await this.profileService.getUserProfile(false);
            this.kontakt.title = this.userProfile.mainInsurer.title;
            this.kontakt.salutation = this.userProfile.mainInsurer.salutation;
            this.kontakt.firstname = this.userProfile.mainInsurer.firstname;
            this.kontakt.lastname = this.userProfile.mainInsurer.lastname;
            this.kontakt.street = this.userProfile.mainInsurer.street;
            this.kontakt.streetNo = this.userProfile.mainInsurer.streetNo;
            this.kontakt.city = this.userProfile.mainInsurer.city;
            this.kontakt.zip = this.userProfile.mainInsurer.zip;
            this.kontakt.addressSupplement = this.userProfile.mainInsurer.addressSupplement;
            this.kontakt.kvnr = this.userProfile.mainInsurer.kvnr;
            this.kontakt.namePrefix = this.userProfile.mainInsurer.namePrefix;
        }
    }

    public async submit() {
        this.notificationService.clear();
        this.kontakt.serverNotifications = [];
        var isValid = await this.validationController.validate();
        this.eventAggregator.publish(Constants.inputValidatedEvent);
        if (isValid.valid == true) {
            try {
                let response = await this.feedbackService.createFeedback(this.activeTenant.id, this.loyaltyProgram.id, this.kontakt);

                if (response.exception != undefined) {
                    //handle exception
                    this.handleFeedbackException(response);
                    isValid = await this.validationController.validate();
                    this.setNotificationForCheckingEntries();
                }
                else {
                    this.feedbackSent = true;
                }
            } catch (error) {
                this.notificationService.error(this.serverErrorMessage);
            }

        }
        else{
           this.setNotificationForCheckingEntries();
        }
    }

    private setNotificationForCheckingEntries(){
        this.notificationService.error("Bitte überprüfen Sie Ihre Angaben!");
    }

    private setValidationRules() {
        ValidationRules
            .ensure((item: FeedbackViewModel) => item.message).required().withMessage("Bitte eine Nachricht eingeben.")
            .ensure((item: FeedbackViewModel) => item.subject).required().withMessage("Bitte wählen Sie einen Betreff aus der Liste aus.")
            .ensure((item: FeedbackViewModel) => item.kvnr)
            .minLength(9).withMessage("Mindestens 9 Zeichen.")
            .maxLength(10).withMessage("Nicht mehr als 10 Zeichen.")
            .matches(new RegExp(/\w{9,10}/)).withMessage("Nur Zahlen oder Buchstaben.")
            .then().satisfies((val, obj) => this.hasServerError("kvnr") === false).withMessage('Bitte überprüfen Sie die Versichertennummer.')

            .ensure((item: FeedbackViewModel) => item.email)
            .required().withMessage("Bitte geben Sie eine gültige E-Mail-Adresse ein.")
            .email().withMessage("Bitte geben Sie eine gültige E-Mail-Adresse ein.")
            .then().satisfies((val, obj) => this.hasServerError("email") === false).withMessage('Ungültige E-Mail-Adresse.')

            .ensure((item: FeedbackViewModel) => item.firstname)
            .required().withMessage('Bitte füllen Sie den Vornamen aus.')
            .then().satisfies((val, obj) => this.hasServerError("firstname") === false).withMessage('Bitte überprüfen Sie den Vornamen.')
            .ensure((item: FeedbackViewModel) => item.lastname)
            .required().withMessage('Bitte füllen Sie den Nachnamen aus.')
            .then().satisfies((val, obj) => this.hasServerError("lastname") === false).withMessage('Bitte überprüfen Sie den Nachnamen.')

            .ensure((item: FeedbackViewModel) => item.birthdate)
            .required().withMessage('Bitte geben Sie Ihr Geburtsdatum an.')
            .then().satisfies((val, obj) => this.hasServerError("birthdate") === false).withMessage('Bitte überprüfen Sie das Geburtsdatum.')

            .ensure((item: FeedbackViewModel) => item.street)
            .required().withMessage('Bitte geben Sie den Straßennamen an.')
            .ensure((item: FeedbackViewModel) => item.zip)
            .required().withMessage('Bitte geben Sie die Postleitzahl an.')
            .then().satisfies((val, obj) => this.hasServerError("zip") === false).withMessage('Bitte überprüfen Sie die Postleitzahl.')

            .ensure((item: FeedbackViewModel) => item.city)
            .required().withMessage('Bitte geben Sie den Ort an.')
            .then().satisfies((val, obj) => this.hasServerError("city") === false).withMessage('Bitte überprüfen Sie den Ort.')

            .ensure((item: FeedbackViewModel) => item.streetNo)
            .required().withMessage('Bitte geben Sie die Hausnummer an.')
            .ensure((item: FeedbackViewModel) => item.salutation)
            .required().withMessage('Bitte wählen Sie eine Anrede aus.')
            .then().satisfies((val, obj) => this.hasServerError("salutation") === false).withMessage('Bitte überprüfen Sie die Anrede.')

            .ensure((item: FeedbackViewModel) => item.countryCode)
            .required().withMessage('Bitte wählen Sie ein Land aus.')
            .then().satisfies((val, obj) => this.hasServerError("countrycode") === false).withMessage('Bitte überprüfen Sie das Land.')

            .ensure((item: FeedbackViewModel) => item.privacyStatementAcceptet)
            .required().withMessage('Bitte akzeptieren Sie die Datenschutzhinweise.')

            .on(this.kontakt);
    }
    private handleFeedbackException(exception: FeedbackResultViewModel) {

        switch (exception.exception.toLowerCase()) {
            case "methodargumentnotvalidexception":
                exception.errorMessages.forEach(element => {
                   switch(element.toLocaleLowerCase()){
                       case "feedbackdto.lastname":
                            this.kontakt.serverNotifications.push("lastname")
                            break;
                        case "feedbackdto.firstname":
                            this.kontakt.serverNotifications.push("firstname")
                            break;
                        case "feedbackdto.zip":
                            this.kontakt.serverNotifications.push("zip")
                            break;
                        case "feedbackdto.city":
                            this.kontakt.serverNotifications.push("city")
                            break;
                        case "feedbackdto.salutation":
                            this.kontakt.serverNotifications.push("salutation")
                            break;
                        case "feedbackdto.kvnr":
                            this.kontakt.serverNotifications.push("kvnr")
                            break;
                        case "feedbackdto.street":
                            this.kontakt.serverNotifications.push("street")
                            break;
                        case "feedbackdto.countrycode":
                            this.kontakt.serverNotifications.push("countrycode")
                            break;
                        case "feedbackdto.birthdate":
                            this.kontakt.serverNotifications.push("birthdate")
                            break;
                    }
                });
                break;
            case "invalidzipexception":
                //this.validationController.addError("plZ", this.teilnehmer, "Postleitzahl");
                this.kontakt.serverNotifications.push("zip")
                break;

            case "invalidemailexception":
                this.kontakt.serverNotifications.push("email")
                break;
            case "invalidkvnrexception":
                this.kontakt.serverNotifications.push("kvnr")
                break;
            case "kvnralreadyexistingexception":
                this.kontakt.serverNotifications.push("kvnr")
                break;
            case "addressvalidationnotavailable":
                this.notificationService.error("Bitte prüfen Sie die Eingabe Ihrer Adresse.");
                break;

            default:
                this.notificationService.error(this.serverErrorMessage);
                break;
        }

    }
    private hasServerError(field:string):boolean {
        if (this.kontakt.serverNotifications.indexOf(field) > -1 )
        {
            return true;
        }
        return false;
    }

}