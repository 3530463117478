import { NewsViewModel } from './../view-models/news-view-model';
import { TenantService } from './../service/tenant/tenant-service';
import { PageBase } from './contents/Base/page-base';
import { TenantViewModel, ProgramInfo } from './../view-models/tenant-view-models';
import { autoinject } from 'aurelia-framework';
import { AuthenticationProvider, ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";
import { NewsService } from '../service/news/news-service';

@autoinject
export class Start extends PageBase {

    tenantInfo: ProgramInfo;
    public title: string = '';
    public activeTenant: TenantViewModel;
    public newsCollection: NewsViewModel[] = [];
    public newsDisplayModel: NewsViewModel[] = [];
    public showMoreNewsButton: boolean = true;
    public divClassHeroStyle: string = "";

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, public layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private authProvider: AuthenticationProvider, private newsService: NewsService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);
        this.layout.diasbaleBreadCrumbs();
       
    }

    public async login() {
        await this.authProvider.login(window.location.href.indexOf('?') != -1 ? (window.location.href + "&") : (window.location.href + "?"));
    }

    public async logout() {
        await this.authProvider.logout(window.location.href.indexOf('?') != -1 ? (window.location.href + "&") : (window.location.href + "?"));
        await this.nav.navigate("start");
    }

    public async attached() {
        this.setActiveTenant();
        this.divClassHeroStyle = "background-image:url('" + this.rootPath + "Assets/" + this.program + "_" + this.activeTenant.key + ".jpg');";
        var loyalityProgram = this.getLoyalityProgram();
        this.newsCollection = await this.newsService.getNewsForStartPage(this.activeTenant.id, loyalityProgram.id);
        var maxNews = 3;
        this.newsDisplayModel = this.newsCollection.slice(0, maxNews);
        this.showMoreNewsButton = this.newsCollection.length > maxNews;
    }

    public async loadMoreNews() {
        if (this.newsCollection.length > 9) {
            this.newsDisplayModel = this.newsCollection.slice(0, 9);
        }
        else {
            this.newsDisplayModel = this.newsCollection;
        }

        this.showMoreNewsButton = false;
    }

    public async setActiveTenant() {
        this.activeTenant = this.tenantProvider.getTenant();        
        this.tenantInfo = this.activeTenant.ui.programs[0];
    }
}