import { ChequebookDeliveryMethod, MemebershipState, ProfileInformation, ProfileViewModel } from './../view-models/profile-view-model';
import { PageBase } from './contents/Base/page-base';
import { autoinject, observable } from 'aurelia-framework';
import { ValidationController, ValidationRules, ValidationControllerFactory } from 'aurelia-validation';
import { ConfigProvider, Logger, NotificationService } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from '../service/ui/ui-layout-service';
import { UiNavigationService } from '../service/ui/ui-navigation-service';
import { ProfileService } from '../service/profile/profile-service';
import { AuthService } from '../service/auth/auth-service';
import { BootstrapErrorRenderer } from './contents/CustomElements/BootstrapErrorRenderer';
import { SalutationViewModel } from '../view-models/salutation-view-model';
import { ErrorDTO } from '../service/api/aok-rest-api';

@autoinject()
export class Profile extends PageBase {

    public userProfile: ProfileViewModel;
    public options = { year: 'numeric', month: 'long', day: 'numeric' };
    public zustellungsMethoden: Array<ChequebookDeliveryMethod> = new Array<ChequebookDeliveryMethod>();
    public emailTextBoxEnabled = false;
    public tenant: string = '';
    public email: string = '';
    public controlReadonly: boolean = true;
    public profileSaluatation: string;
    public duplicateEmailError: boolean = false;
    public anrede: Array<SalutationViewModel> = new Array<SalutationViewModel>();

    @observable
    public zustelleungsWeg: ChequebookDeliveryMethod = ChequebookDeliveryMethod.NONE;

    private validationController: ValidationController;

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private profileService: ProfileService, private notificationService: NotificationService,
        private validationControllerFactory: ValidationControllerFactory, private auth: AuthService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        this.userProfile = new ProfileViewModel();
        this.userProfile.mainInsurer = new ProfileInformation();
        this.userProfile.mainInsurer.membership = new MemebershipState();

        if (!this.auth.IsAuthenticated()) {
            this.nav.navigate("start");
        } else {
            this.tenant = this.tenantProvider.getTenant().key;
        }

        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapErrorRenderer());

        ValidationRules.ensure("email")
            .required().withMessage('Bitte geben Sie Ihre E-Mail-Adresse an.')
            .when((p: this) => p.zustelleungsWeg == ChequebookDeliveryMethod.ONLINE)
            .email().withMessage('Bitte geben Sie eine gültige E-Mail-Adresse ein.')
            .when((p: this) => p.zustelleungsWeg == ChequebookDeliveryMethod.ONLINE)             
            .maxLength(40).withMessage("Nicht mehr als 40 Zeichen. ")         
            .then().satisfies((val, obj) => !this.duplicateEmailError).withMessage("Die E-Mail-Adresse ist bereits vergeben. ")
            .on(this);
        this.initalize();
    }

    async attached() {

        if (this.auth.IsAuthenticated()) {
            this.userProfile = await this.profileService.getUserProfile(true);
            this.zustelleungsWeg = this.userProfile.mainInsurer.membership.chequebookDelivery;
            this.email = this.userProfile.mainInsurer.membership.email;
            this.profileSaluatation = this.anrede.filter(anrede => anrede.salutationId == this.userProfile.mainInsurer.salutation)[0].description;
            this.initilizeCollections();
        }
    }

    async zustelleungsWegChanged() {
        this.emailTextBoxEnabled = this.zustelleungsWeg != ChequebookDeliveryMethod.ONLINE;
    }

    private initilizeCollections() {
        this.zustellungsMethoden.push(ChequebookDeliveryMethod.LETTER);
        this.zustellungsMethoden.push(ChequebookDeliveryMethod.ONLINE);
    }

    async updateChequebookDelivery() {
        this.userProfile.mainInsurer.membership.chequebookDelivery = this.zustelleungsWeg;
        this.userProfile.mainInsurer.membership.email = this.email;

        if (await this.validateFields()) {
            try {
                await this.profileService.updateCheckbookDelivery(this.zustelleungsWeg, this.email);
                this.notificationService.success("Ihre Daten wurden aktualisiert");
            }
            catch (error) {
                if (error instanceof ErrorDTO && error.errorCode == "EMAIL_NOT_UNIQUE") {                    
                    this.duplicateEmailError = true;
                    this.notificationService.error("Bitte überprüfen Sie Ihre Angaben!");
                    await this.validationController.validate();
                }
                else
                    this.notificationService.error("Bei der Aktualisierung Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. Sollte der Fehler weiterhin auftreten, so kontaktieren Sie gerne unseren Support.");
            }
        }
    }

    private async validateFields(): Promise<boolean> {
        var result = await this.validationController.validate();
        return result.valid;
    }

    getDisplayNameForChequebookDelivery(zustellungMethode: ChequebookDeliveryMethod): string {
        switch (zustellungMethode) {
            case ChequebookDeliveryMethod.NONE:
                return "None";
            case ChequebookDeliveryMethod.ONLINE:
                return "Online";
            case ChequebookDeliveryMethod.LETTER:
                return "Post";
        }
    }

    navigateTochangePassword() {
        this.nav.navigate("passwort ändern");
    }

    private initalize() {
        this.anrede = [
            { salutationId: "MALE", description: "Herr" },
            { salutationId: "FEMALE", description: "Frau" },
            { salutationId: "DIVERSE", description: "Divers" }
        ];
    }
}